import { createGlobalStyle, css } from 'styled-components'
import { colors } from './variables'

const style = css`
  html {
    font-family: 'Quicksand', sans-serif;
    color: ${colors.text};
  }
`

const Global = createGlobalStyle`
  ${style}
`

export default Global
