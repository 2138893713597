import React, { ReactNode, FC } from 'react'
import styled from 'styled-components'
import Global from '../../styles/global'
import Reset from '../../styles/reset'
import HelmetWrapper from '../HelmetWrapper'

const Main = styled.main`
  overflow-x: hidden;
`

interface Props {
  children: ReactNode
}

const Layout: FC<Props> = (props) => {
  const { children } = props

  return (
    <>
      <Reset />
      <Global />
      <HelmetWrapper />
      <Main>{children}</Main>
    </>
  )
}

export default Layout
