export const spacing = {
  size01: '4px',
  size02: '8px',
  size03: '12px',
  size04: '16px',
  size05: '24px',
  size06: '32px',
  size07: '48px',
  size08: '64px',
  size09: '96px',
  size10: '128px',
  size11: '192px',
  size12: '256px',
  size13: '384px',
  size14: '512px',
  size15: '640px',
  size16: '768px',
}

export const fontSize = {
  size01: '12px',
  size02: '14px',
  size03: '16px',
  size04: '18px',
  size05: '20px',
  size06: '24px',
  size07: '30px',
  size08: '36px',
  size09: '48px',
  size10: '60px',
  size11: '72px',
}

export const colors = {
  main: '#FBCB0C',
  white: '#FFFFFF',
  text: '#252A2A',
}
