import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

const HelmetWrapper: FC = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>My Fyzio</title>
      <link
        href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  )
}

export default HelmetWrapper
